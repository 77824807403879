import "./App.css";
import cover from "./assets/cover.jpg";
import samlevin from "./assets/samlevin.jpg";
import Bars from "./icons/Bars";
import { useState } from "react";
import { BandCampLink, FacebookLink, InstagramLink } from "./socialLinks";

function App() {
  const [menuOverlay, triggerMenuOverlay] = useState(false);

  return (
    <div className="min-h-screen w-screen bg-neutral-100 text-neutral-900">
      {menuOverlay && (
        <div className="absolute inset-0 opacity-90 bg-neutral-900 text-center text-2xl text-neutral-100">
          <div className="px-4 py-8 mb-2">
            <a onClick={() => triggerMenuOverlay(false)} href="#about">
              About
            </a>
          </div>
          <div className="px-4 py-8 mb-2">
            <a onClick={() => triggerMenuOverlay(false)} href="#media">
              Media
            </a>
          </div>
          <div className="px-4 py-8 mb-2">
            <a onClick={() => triggerMenuOverlay(false)} href="#contact">
              Contact
            </a>
          </div>
          <div className="px-4 py-8">
            <a onClick={() => triggerMenuOverlay(false)} href="#social">
              Social
            </a>
          </div>
        </div>
      )}
      <div className="w-full min-h-screen">
        <div className="md:px-8 py-4 flex md:space-x-12 justify-center items-center">
          <div className="hidden md:flex space-x-8 w-1/3 text-xl">
            <div>
              <a href="#about">About</a>
            </div>
            <div>
              <a href="#media">Media</a>
            </div>
            <div>
              <a href="#contact">Contact</a>
            </div>
          </div>
          <div className="md:w-1/3 text-3xl md:text-6xl lg:text-6xl text-center">
            <div
              className="absolute top-4 left-4 md:hidden z-100"
              onClick={() => {
                triggerMenuOverlay((prevState) => !prevState);
              }}
            >
              <Bars className="w-8" />
            </div>
            Sam Levin
          </div>
          <div className="w-1/3 hidden md:flex space-x-8 justify-end">
            <BandCampLink />
            <InstagramLink />
            {/*<Spotify className="w-6" />*/}
            <FacebookLink />
          </div>
        </div>
        <div className="w-full md:flex md:flex-row-reverse md:justify-around ">
          <div className="w-5/6 md:w-1/2 lg:w-3/8 m-8 lg:mx-24">
            <img
              src={cover}
              className="w-full"
              alt="album cover - abstract heavenly architecture in the sky"
            />
          </div>
          <div className="w-5/6 md:w-1/2 lg:w-5/8 lg:mx-24 py-12 m-8 md:px-16 indent-5 text-justify">
            <p>
              Sam Levin hails from Brooklyn, New York, but his trio's first
              release, Another Brooklyn, was entirely written, conceived and
              recorded in Silesia, Poland. While the music highlights complex
              rhythmic concepts, the original compositions all support heavy
              grooves and strong band interplay. Both Kuba Mizeracki and Mateusz
              Szewczyk contribute distinctive voices as soloists and
              improvisers. Due to the small size of the ensemble and the
              stripped down nature of the compositions, the album has an
              intimate feeling which emphasizes the spur-of-the-moment group
              dynamic. The music is oriented in the world of contemporary jazz,
              but it features a strong rock influence in composition and
              performance.
            </p>
            <p className="mt-4 underline">
              <a
                href="http://samklevin.bandcamp.com"
                target="_blank"
                rel="noreferrer"
              >
                Buy the album on bandcamp
              </a>
            </p>
          </div>
        </div>
        <div
          id="about"
          className="min-h-screen md:flex md:justify-around py-4 bg-neutral-900 text-neutral-100"
        >
          <div className="w-5/6 md:w-1/2 lg:w-3/8 m-8 lg:mx-24">
            <img src={samlevin} alt="Sam Levin on stage" />
            <div className="w-full text-left">
              Jazz w Ruinach - Gliwice, Poland - Aug '22
            </div>
          </div>
          <div className="w-5/6 md:w-1/2 lg:w-5/8 lg:mx-24 mx-auto py-12 md:px-16 indent-5 text-justify">
            <p className="indent-5">
              Sam was born and raised near Berkeley, California. He started
              playing the drums at the age of nine, on a set of pots and pans.
              While living in the Bay Area, Sam studied with Pete Magadini, who
              exposed him to polyrhythmic concepts which continue to influence
              Sam's playing and composition today. He continued his focus on
              polyrhythmic concepts, studying extensively with Ari Hoenig, even
              performing in Ari's piano trio.
            </p>
            <p className="indent-5">
              Sam has toured extensively in the US and Europe with a broad range
              of artists including Elysian Fields, Stuart Bogie's Superhuman
              Happiness and R Stevie Moore. He also has had the opportunity to
              record with top producers such as Shahzad Ismaily, Alex Newport
              and Thomas Bartlett.
            </p>
            <p className="indent-5">
              During the pandemic, Sam unexpectedly moved to Tychy, Poland,
              where he returned his focus to jazz. As the pandemic lifted, Sam
              discovered a thriving creative scene in nearby Katowice. It didn't
              take long to form strong musical connections in this community.
              The new album, Another Brooklyn, is the result of Sam's creative
              work in isolation and his new musical relationships in Poland.
            </p>
          </div>
        </div>
      </div>
      <div id="media" className="w-full py-24 w-full flex justify-center">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/TYrNkr968ko"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div
        id="contact"
        className="w-full py-36 md:flex bg-neutral-900 text-neutral-100"
      >
        <div className="md:w-1/3 px-8 md:px-12 mb-12 text-left">
          <p className="mb-4">
            Get in touch for bookings, lesson inquiries or help with
            non-sensical Polish translations.
          </p>
          <p className="mb-4">
            You can also feel free to reach out to me on social media.
          </p>
          <div className="mt-4 flex justify-center space-x-8">
            <BandCampLink className="text-neutral-100" />
            <InstagramLink className="text-neutral-100" />
            {/*<Spotify className="w-6" />*/}
            <FacebookLink className="text-neutral-100" />
          </div>
        </div>
        <div className="md:w-2/3 px-12">
          <div>email me: slevindrums (at) gmail.com</div>
          <div>label contact: cmrecords (at) cmrecords.eu</div>
        </div>
      </div>
    </div>
  );
}

export default App;
