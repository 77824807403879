import Bandcamp from "./icons/Bandcamp";
import Instagram from "./icons/Instagram";
import Facebook from "./icons/Facebook";

export const BandCampLink = ({ className }) => (
  <a href="http://samklevin.bandcamp.com" target="_blank" rel="noreferrer">
    <Bandcamp className={`w-6 ${className}`} />
  </a>
);

export const InstagramLink = ({ className }) => (
  <a
    href="https://www.instagram.com/s.k.levin/"
    target="_blank"
    rel="noreferrer"
  >
    <Instagram className={`w-6 ${className}`} />
  </a>
);

export const FacebookLink = ({ className }) => (
  <a
    href="https://www.facebook.com/profile.php?id=833838"
    target="_blank"
    rel="noreferrer"
  >
    <Facebook className={`w-6 ${className}`} />
  </a>
);
